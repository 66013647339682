import { ref } from "vue";
import { defineStore } from "pinia";
import { useStore } from "vuex";
import { NAV_ACTIONS } from "../actions";
import LicenseApi from "@/api/license";
import { useIntegrationsStore } from "./integrations";

const FEATURE_DEFAULTS = {
    cdfEditor: false,
    cssEditor: false,
    closingMenuEntry: false,
    dataConversion: false,
    eRemit: false,
    eRemitFragment: false,
    standardFees: false,
    reportDashboard: false,
    auditlog: false,
    recordingDocuments: false,
    arcIntegration: false,
    checkWritingPIN: false,
    titleProductionPIN: false,
    ezJacket: false,
    cpl: false,
    ortCpl: false,
    epicorIntegration: false,
    epnNextConnect: false,
    superMarketAccountVerification: false,
    superMarketAddressAndTaxIdVerification: false,
    orderTimeTracking: false,
    pavasoDigitalSignings: false,
    //defaulting to true until backed by proper licensing
    pdfEditing: true,
    pdfBookmarks: true,
    pdfAnnotations: true,
    pdfFormCreator: true,
    pdfRedaction: true,

    // Calculator dialog access
    dashboardDialogs: false,

    // Troubleshooting/utility
    bypassForcedLogoutOnError: false,
    ssDocActions: false,
    clientSessionLogging: false,

    //IRS 1099 microservice
    irs1099Service: false,
    irs1099ServiceIRIS: false
};

export const useLicenseStore = defineStore("license", () => {
    const vuexStore = useStore();
    const integrationsStore = useIntegrationsStore();

    const features = ref(FEATURE_DEFAULTS);
    const featuresLoaded = ref(false);
    const additionalFeatures = ref({});

    async function getFeatures(additional={}) {
        let result = await LicenseApi.getFeatures();
        if(!_.isEmpty(additional)) {
            additionalFeatures.value = additional;
        }
        features.value = {
            ...FEATURE_DEFAULTS,
            ...result.flags,
            ...additionalFeatures.value
        };
        featuresLoaded.value = true;
        vuexStore?.dispatch(NAV_ACTIONS.REFRESH_NAV);
        integrationsStore.setIntegrations(result.services);
        return result;
    }

    async function patchFeatures(flags) {
        if(!featuresLoaded.value) {
            await getFeatures(flags);
        }
        else {
            additionalFeatures.value = _.merge({}, additionalFeatures.value, flags);
            features.value = _.merge({}, features.value, flags);
        }
        vuexStore?.dispatch(NAV_ACTIONS.REFRESH_NAV);
    }

    const checkFeature = name => {
        if(!featuresLoaded.value) return false;
        return _.getBool(features.value, name);
    };

    return {
        features,
        featuresLoaded,
        getFeatures,
        patchFeatures,
        checkFeature
    };
});
