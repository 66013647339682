<template>
    <div :class="classAttr">
		<editor
            v-if="contentLoaded"
			:init="editorConfig"
            :plugins="editorPlugins"
            :toolbar="editorToolbar"
            :disabled="readOnly"
            tinymce-script-src="/js/tinymce-lib/tinymce/tinymce.min.js"
			v-model="editorContent"
            @focusIn="onEditorFocusIn"
            @blur="onEditorBlur"
            @dirty="onContentDirty"
            @change="onContentChange"
		/>
    </div>
</template>

<script>
    import Editor from "@tinymce/tinymce-vue";
    import { BasePlugins, BaseToolbar, QuickSelectionToolbar, QuickInsertToolbar, BaseMenuConfig } from "./HtmlEditorUtilities";
    import StandardLanguageDialog from "@documents/components/prompts/StandardLanguageDialog";
    import { DocHyperlinkForm } from "@documents/components/editor-dialogs";

    export default {
        name: "RqHtmlEditor",
        components: { Editor },
        props: {
            automationId: { type: String },
            modelValue: { type: String, default: "" },
            placeholder: { type: String, default: "" },
            height: { type: [Number,String], default: "100%" },
            customMenuItems: { type: Array, default: () => [] },
            customToolbarItems: { type: Array, default: () => [] },
            standardLanguageOptions: {
                type: Object,
                default: () => ({
                    enabled: false,
                    defaultCategoryId: 0,
                    categories: []
                })
            },
            inline: { type: Boolean, default: false },
            inlineAutoResize: { type: Boolean, default: true },
            autoHideToolbar: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false },
            strikeThrough: { type: Boolean, default: false },
            showInsertDocLink: { type: Boolean, default: false },
        },

        data () {
            const self = this;
            return {
                editorContent: "",
                initialValue: null,
                documentName: "Untitled",
                contentLoaded: false,
                madeChanges: true,
                editorFocused: !self.inline,
                focusOnInit: false,
                editorDialogActive: false,
                pendingEvents: []
            };
        },

        emits: [
            "update:modelValue",
            "focus",
            "blur",
            "change",
            "dirty"
        ],

        computed: {
            classAttr() {
                return {
                    "rq-html-editor-container": true,
                    "rq-html-editor-inline": this.inline,
                    "rq-html-editor-focused": this.editorFocused,
                    "autohide-toolbar": this.autoHideToolbar
                };
            },
            toolbarVisible() { return !this.inline || this.editorFocused; },
            autoResize() { return this.inline && this.inlineAutoResize; },
            editorPlugins() {
                let pluginList = BasePlugins();

                if(this.autoResize) {
                    pluginList.push("autoresize");
                }

                return _.join(pluginList, " ");
            },
            editorToolbar() {
                if(this.inline) return "";
                let toolbarItems = BaseToolbar();
                if(_.isEmpty(this.customToolbarItems)) return toolbarItems;
                let customItems = _.join(_.map(this.customToolbarItems, "id"), " ");
                return `${toolbarItems} | ${customItems}`
            },
            editorMenu() {
                if(this.inline) return false;
                let menubar = BaseMenuConfig();
                if(!_.isEmpty(this.customMenuItems)) {
                    let mainMenuItems = _.mapValues(_.groupBy(this.customMenuItems, "parent"), groupItems => _.join(_.map(groupItems, "id"), " "));
                    _.forEach(mainMenuItems, (val, key) => {
                        if(_.toLower(key) === "utilities") {
                            menubar.utilities = { title: "Utilities", items: val };
                            return;
                        }
                        menubar[_.toLower(key)].items = `${val} | ${menubar[_.toLower(key)].items}`;
                    });
                }
                if(this.standardLanguageOptions.enabled) {
                    menubar.insert.items = "standardlanguage | " + menubar.insert.items;
                }
                return menubar;
            },
            editorContentStyle() {
                return `body { ${ this.inline && this.autoResize ? "margin: 0;" : "" } font-size: 10pt; font-family: Times New Roman; } .rq-strike-through { text-decoration: line-through !important; }`;
            },
            editorConfig() {
                const self = this;
                let result = {
                    height: this.autoResize ? "auto" : this.height,
                    placeholder: this.placeholder,
					menubar: false,
					image_advtab: true,
					//spellchecker_ignore_list: self.userSpellCheckDictionary,
					contextmenu: "undo redo | inserttable | link image imagetools table",
                    elementpath: false,
                    statusbar: false,
                    setup: self.setupEditor,
                    content_style: self.editorContentStyle,
                    init_instance_callback: editor => {
                        self.editorInstance = editor;
                        if(!self.focusOnInit) return;
                        self.focusOnInit = false;
                        self.editorInstance.focus();
                    }
				};
                if(self.inline) {
                    if(self.autoResize){
                        result.min_height = 50;
                        result.autoresize_bottom_margin = 10;
                    }
                    if(self.strikeThrough) {
                        result.body_class = "rq-strike-through";
                    }
                    result.toolbar = false;

                    let quickSelectItems = QuickSelectionToolbar();
                    let quickInsertItems = QuickInsertToolbar();

                    result.quickbars_selection_toolbar = self.showInsertDocLink ? _.replace(quickSelectItems, "link", "link rq_document_link") : quickSelectItems;
					result.quickbars_insert_toolbar = self.showInsertDocLink ? _.replace(quickInsertItems, "link", "link rq_document_link") : quickInsertItems;
                }
                else {
                    result.menubar = _.join(_.keys(self.editorMenu), " ");
                    result.menu = self.editorMenu;
                }
                return result;
            }
        },

        watch: {
            modelValue: {
                handler(newVal, oldVal) {
                    if(newVal === oldVal || newVal === this.editorContent) return;
                    this.editorContent = newVal;
                },
                immediate: true
            },
            editorContent(newVal, oldVal) {
                if(newVal === oldVal || newVal === this.modelValue) return;
                this.madeChanges = newVal !== this.initialValue;
                this.$emit("update:modelValue", newVal);
            },
            strikeThrough(newVal, oldVal) {
                this.setStrikeThrough();
            }
        },

        created() {
            this.editorInstance = null;
        },

        mounted() {
            this.initialValue = this.modelValue;
            this.$nextTick(() => {
                this.contentLoaded = true;
            });
        },

        methods: {
            setupEditor(editor) {
                const self = this;

                self.registerButtonItem(editor, {
                    id: "rq_document_link",
                    icon: "rq-fad-file-export",
                    tooltip: "Doc Link"
                });

                if(self.inline) return;
                _.each(self.customToolbarItems, item => {
                    switch(item.type) {
                        case "dropdown":
                            /* dropdown button example
                                {
                                    id: "rq-custom-fields-dropdown",
                                    text: "Custom Fields",
                                    type: "dropdown",
                                    fetch(callback) {
                                        let items = _.map(self.customFields, field => ({
                                            type: "menuitem",
                                            text: field.label,
                                            onAction() {
                                                self.insertContent(field.value);
                                            }
                                        }));
                                        callback(items);
                                    }
                                }
                            */
                            editor.ui.registry.addMenuButton(item.id, item);
                            break;
                        default:
                            self.registerButtonItem(editor, item);
                            break;
                    }
                });
                let menuItems = self.customMenuItems.slice();
                if(self.standardLanguageOptions.enabled) {
                    menuItems.push({ id: "standardlanguage", text: "Standard Language", icon: "rq-far-list-alt" });
                }
                const menuIconSetup = item => () => {
                    $(`.tox-collection__item[title='${item.tooltip || item.text}'] .tox-collection__item-icon`).html(`<svg class='rq-icon-symbol-lg fa-fw'><use href='#${item.icon}'></use></svg>`);
                    return () => {};
                };
                const addNestedMenuItem = item => {
                    let nestedItem = {
                        text: item.text,
                        tooltip: item.tooltip || item.text,
                        getSubmenuItems: item.getSubmenuItems
                    };
                    if(item.icon) nestedItem.onSetup = menuIconSetup(item);
                    console.log(nestedItem);
                    editor.ui.registry.addNestedMenuItem(item.id, nestedItem);
                };
                _.each(menuItems, item => {
                    switch(item.type) {
                        case "dropdown":
                            addNestedMenuItem(item);
                            break;
                        default:
                            editor.ui.registry.addMenuItem(item.id, {
                                text: item.text,
                                icon: item.icon,
                                tooltip: item.tooltip || item.text,
                                onSetup: menuIconSetup(item),
                                onAction() { self.onToolbarAction(item); }
                            });
                            break;
                    }
                });
            },

            registerButtonItem(editor, item) {
                const self = this;
                editor.ui.registry.addButton(item.id, {
                    text: item.text || item.id,
                    tooltip: item.tooltip,
                    onSetup() {
                        //if item.text doesn't exist, text is set to item.id and will be
                        //replaced with the configured item.icon fontawesome icon here
                        $(".tox-tbtn__select-label").each(function() {
                            if($(this).text() !== item.id) return;
                            $(this).html(`<svg class='rq-icon-symbol-lg fa-fw'><use href='#${item.icon}'></use></svg>`);
                        });
                        return () => {};
                    },
                    onAction() { self.onToolbarAction(item); }
                });
            },

            onToolbarAction(item) {
                const self = this;
                if(_.isEmpty(item) || !item.id) return;
                if(item.id === "standardlanguage") {
                    self.launchClauseSelectionDialog();
                    return;
                }
                if(item.id === "rq_document_link") {
                    self.launchDocLinkDialog();
                    return;
                }
                self.$emit("toolbar-action", { item });
            },

            load(name="Untitled", content) {
                this.documentName = name || "Untitled";
                this.editorContent = content || "";
                this.contentLoaded = true;
            },

            insertContent(content) {
                const self = this;
                if(_.isEmpty(content)) return;
                _.invoke(self, "editorInstance.insertContent", content);
            },

            launchClauseSelectionDialog () {
                const self = this;
                if(self.readOnly) return;

                self.setDialogActiveState(true);

                const onOk = async (e) => {
                    let isValid = await e.component.validate();
                    if(isValid) {
                        let dialogResult = e.component.getResult();
                        self.insertStandardLanguages(dialogResult.items, dialogResult.linesBetweenClauses);
                    }
                    return isValid;
                };

                let categoryId = _.getNumber(self, "standardLanguageOptions.defaultCategoryId", null);
                let categories = _.get(self, "standardLanguageOptions.categories", null);
                self.$dialog.open({
                    title: "Clause Selection",
                    height: "80%",
                    width: "80%",
                    component: StandardLanguageDialog,
                    props: { categoryId, categories },
                    onOk,
                    onClosed: self.onDialogClosed
                });
            },

            async insertStandardLanguages(standardLanguages, linesBetweenClauses) {
                const self = this;
                let idList = _.map(standardLanguages, "standardLanguageID");
                let apiPromise = self.$api.DocumentsApi.getStandardLanguageListContent(idList, linesBetweenClauses, "html");
                let result = await self.$rqBusy.wait(apiPromise)
                self.insertContent(result);
            },

            launchDocLinkDialog () {
                const self = this;
                if(self.readOnly) return;

                self.setDialogActiveState(true);

                const onOk = e => {
                    if(e.component.textIsEmpty || e.component.urlIsEmpty) return true;
                    let urlInfo = e.component.urlInfo;
                    if(!urlInfo.isValid) return true;
                    self.insertHyperlink(urlInfo);
                    return true;
                }

                let hyperlinkInfo = self.getSelectedHyperlinkInfo();
                self.$dialog.open({
                    title: "Insert Doc Hyperlink",
                    width: 600,
                    height: 350,
                    resizable: false,
                    component: DocHyperlinkForm,
                    props: {
                        text: hyperlinkInfo.text,
                        url: hyperlinkInfo.url,
                        tooltip: hyperlinkInfo.tooltip
                    },
                    onOk,
                    onClosed: self.onDialogClosed
                });
            },

            getSelectedHyperlinkInfo() {
                let selectedElement = _.invoke(this, "editorInstance.selection.getEnd");
                let selectedText = _.invoke(this, "editorInstance.selection.getContent");
                return {
                    text: selectedText,
                    url: selectedElement?.href || "",
                    tooltip: selectedElement?.title || ""
                };
            },

            insertHyperlink(urlInfo) {
                //TODO: parse surrounding elements to retain font size/family
                this.insertContent(`<a href="${urlInfo.url}" title="${urlInfo.tooltip}">${urlInfo.text}</a>`);
            },

            async onDialogClosed() {
                this.focus();
                await this.$nextTick();
                this.setDialogActiveState(false);
                await this.$nextTick();
                //emit any events fired while dialog was active
                this.emitPendingEvents();
            },

            tagSelection(startTag, endTag) {
                const self = this;

                if(_.isEmpty(startTag)) return;

                let content = self.editorInstance.selection.getContent();
                let range = self.editorInstance.selection.getRng();
                let startContainer = range.startContainer;
                let endContainer = range.endContainer;
                let sameNode = startContainer === endContainer;
                let startOffset = range.startOffset;
                let endOffset = sameNode ? startOffset + content.length + 3 : range.endOffset;

                self.editorInstance.selection.setCursorLocation(startContainer, startOffset);
                self.editorInstance.insertContent(startTag);
                if(sameNode) {
                    endContainer = self.editorInstance.selection.getRng().startContainer;
                }
                self.editorInstance.selection.setCursorLocation(endContainer, endOffset);
                self.editorInstance.insertContent(_.isEmpty(endTag) ? startTag : endTag);
            },

            setStrikeThrough() {
                const self = this;
                let bodyElement = self.editorInstance.getBody();
                let strikeThroughClass = "rq-strike-through";
                let containsClass = bodyElement.classList.contains(strikeThroughClass);
                if(self.strikeThrough && !containsClass) {
                    bodyElement.classList.add(strikeThroughClass);
                }
                else if(!self.strikeThrough && containsClass) {
                    bodyElement.classList.remove(strikeThroughClass);
                }
            },

            getContent() { return this.editorContent; },

            focus() {
                if(_.isNil(this.editorInstance)) {
                    this.focusOnInit = true;
                    return;
                }
                this.editorInstance.focus();
            },

            onEditorFocusIn() {
                //if an editor dialog was just closed suppress this event since we wanna maintain scope
                if(this.editorDialogActive) return;
                this.editorFocused = true;
                this.emitEvent("focus");
            },

            onEditorBlur(e) {
                //if an editor dialog was just opened suppress this event since we wanna maintain scope
                if(this.editorDialogActive) return;
                this.editorFocused = false;
                this.emitEvent("blur");
            },

            onContentChange(e) { this.emitEvent("change"); },

            onContentDirty(e) { this.emitEvent("dirty"); },

            emitEvent(eventName) {
                if(this.editorDialogActive) {
                    //keep track of events to emit once an editor dialog has closed
                    this.pendingEvents.push(eventName);
                    return;
                }
                this.$emit(eventName, { content: this.editorContent });
            },

            emitPendingEvents() {
                if(_.isEmpty(this.pendingEvents)) return;
                while(this.pendingEvents.length > 0) {
                    let eventName = this.pendingEvents.shift();
                    this.emitEvent(eventName);
                }
            },

            async refresh() {
                // RQO-14447 - this is a workaround to an existing bug with the TinyMCE vue component: https://github.com/tinymce/tinymce-vue/issues/230
                this.contentLoaded = false;
                await this.$nextTick();
                this.contentLoaded = true;
            },

            setDialogActiveState(isActive=false) {
                this.toggleBodyDialogClass(isActive);
                //"editorDialogActive" will only be "true" when external dialogs are active.  Tracking
                //internal dialog state isn't needed at this time since it doesn't trigger an editor blur
                this.editorDialogActive = isActive;
            },

            //TinyMce provides no way to overrdie classes, set the menu container element, or manually hide menu elements.
            //On top of that, the toolbar menu or quickbar container is rendered directly within the body element.  So our
            //only course of action when launching custom dialogs from the menu is to manually toggle a class on the body
            //to ensure quickbars and menu items are displayed behind the dialog overlay.
            toggleBodyDialogClass(force=null) {
                const className = "html-editor-dialog-active";
                if(_.isBoolean(force))
                    document.body.classList.toggle(className, force);
                else
                    document.body.classList.toggle(className);
            }
        }

    };
</script>