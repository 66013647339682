/************************************************************************************************************************
 *                                                                                                                      *
 *   If you change the name of a route or add a new one, please update Table RouteName to reflect the change as well.    *
 *                                                                                                                      *
************************************************************************************************************************/
import { NAV_IDS } from "./collections";
const itemData = [
    {
        id: NAV_IDS.HOME, name: "home", label: "Home", path: "/", icon: "fas fa-house-chimney", matchRoutePrefix: "home",
        children: [
            { id: NAV_IDS.HOME_RECENT_FILES, name: "home:recent-files", label: "Recent Files", path: "/recent-files" },
            { id: NAV_IDS.HOME_PORTFOLIO_MANAGER, name: "home:portfolio-manager", label: "Portfolio Manager", path: "/portfolio-manager", permissionKeys: ["appPortfolioManager_AppAccess"] },
            // { id: NAV_IDS.HOME_DASHBOARD, name: "home:report-dashboard", label: "Report Dashboard", path: "/report-dashboard", featureFlag: "reportDashboard" },
            { id: NAV_IDS.HOME_WORKFLOW, name: "home:workflow-dashboard", label: "Workflow Dashboard", path: "/workflow-dashboard", accessKey: "WorkflowDashboard_ScreenAccess" },
            // { id: NAV_IDS.HOME_MANAGE_COMPANIES, name: "home:recording-dashboard", label: "Recording Dashboard", path: "/recording-dashboard", featureFlag: "recordingDocuments" },
            // { id: NAV_IDS.HOME_SIGNING_DASHBOARD, name: "home:signing-dashboard", label: "Signing Dashboard", path: "/signing-dashboard", featureFlag: "closingMenuEntry" },
            { id: NAV_IDS.HOME_SEARCH, name: "home:search", label: "Search", path: "/search" },
            { id: NAV_IDS.HOME_MANAGE_COMPANIES, name: "home:manage-companies", label: "Manage Companies", path: "/manage-companies", matchRoutePrefix: "dashboard:mc" },
            // Comment out below in place of Coming Soon til we have Internet Alarms implemented
            // { name: "dashboard:alarms", id: "sb-nav-dashboard-internet-alarms", label: "Internet Alarms", path: "/dashboard/alarms" },
            { id: NAV_IDS.HOME_INTERNET_ALARMS, name: "home:alarms", label: "Alerts", path: "/alarms" },
        ]
    },
    {
        id: NAV_IDS.ORDER, label: "File", path: "/order", icon: "fas fa-file-alt", children: [
            {
                id: NAV_IDS.ORDER_ENTRY, label: "File Information", path: "#",
                children: [
                    { id: NAV_IDS.ORDER_ENTRY_MAIN, name: "oe:main", label: "Main", path: "/order/oe", accessKey: "OrderEntry_ScreenAccess" },
                    { id: NAV_IDS.ORDER_ENTRY_PROPERTIES, name: "oe:op:properties", label: "Properties", path: "/order/oe/properties", matchRoutePrefix: "oe:op", accessKey: "OrderEntry_ScreenAccess" },
                    { id: NAV_IDS.ORDER_ENTRY_CONTACTS, name: "oe:oc:contacts", label: "File Contacts", path: "/order/oe/contacts", matchRoutePrefix: "oe:oc", accessKey: "OrderEntry_ScreenAccess" },
                    { id: NAV_IDS.ORDER_ENTRY_CPL, name: "oe:cpl", label: "CPL", path: "/order/oe/cpl", featureFlag: "cpl", accessKey: "OrderEntry_ScreenAccess"  },
                    { id: NAV_IDS.ORDER_ENTRY_LOANS, name: "oe:ol:loans", label: "Loans", path: "/order/oe/loans", matchRoutePrefix: "oe:ol", accessKey: "OrderEntry_ScreenAccess" },
                    { id: NAV_IDS.ORDER_ENTRY_NOTES, name: "oe:notes", label: "File Notes", path: "/order/oe/notes", accessKey: "Notes_ScreenAccess" },
                    { id: NAV_IDS.ORDER_ENTRY_CUSTOM_PAGE, name: "oe:custom", label: "Custom Page", path: "/order/oe/custom", accessKey: "Custom_ScreenAccess", permissionKeys: ["AdditionalFields_ScreenAccess"] }
                ]
            },
            { id: NAV_IDS.TITLE_PRODUCTION, name: "tp:main", label: "Title Production", path: "/order/commitment/title", matchRoutePrefix: "tp", accessKey: "Commitment_ScreenAccess" },
            { id: NAV_IDS.POLICY, name: "policy:main", label: "Policy", path: "/order/commitment/policy", matchRoutePrefix: "policy", accessKey: "Policy_ScreenAccess" },
            { id: NAV_IDS.PREMIUMS, name: "premiums", label: "Premium Rates", path: "/order/settlement/premiums", accessKey: "Premiums_ScreenAccess" },
            {
                id: NAV_IDS.SETTLEMENT, matchRoutePrefix: "settlement", label: "Settlement Statement", path: "/order/settlement",
                children: [
                    // Actual site links
                    { id: NAV_IDS.SETTLEMENT_DASHBOARD, name: "settlement:settlement-dashboard", label: "Settlement Dashboard", path: "/order/settlement/settlement-dashboard", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.CDF_EDITOR, name: "settlement:cdf-editor", label: "CDF Editor", action: { key: "app-nav-cdf-wysiwyg" }, accessKey: "GenericSettlementStatement_ScreenAccess", featureFlag: "cdfEditor" },
                    { id: NAV_IDS.SETTLEMENT_ORIGINATION_CHARGES_CDF, name: "settlement:origination-section", label: "Loan Charges (A)", path: "/order/settlement/origination-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_TITLE_CHARGES_CDF, name: "settlement:title-section", label: "Title Charges (B & C)", path: "/order/settlement/title-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_PREPAIDS_CDF, name: "settlement:prepaid-section", label: "Prepaids (F)", path: "/order/settlement/prepaid-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_ESCROWS_CDF, name: "settlement:escrow-section", label: "Escrows/Impounds (G)", path: "/order/settlement/escrow-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_OTHER_CHARGES_CDF, name: "settlement:othercost-section", label: "Other Charges (H)", path: "/order/settlement/othercost-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_ORIGINATION_CHARGES_HUD, name: "settlement:origination-section", label: "Loan Charges (800)", path: "/order/settlement/origination-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_PREPAIDS_HUD, name: "settlement:prepaid-section", label: "Prepaids (900)", path: "/order/settlement/prepaid-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_ESCROWS_HUD, name: "settlement:escrow-section", label: "Escrows/Impounds (1000)", path: "/order/settlement/escrow-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_TITLE_CHARGES_HUD, name: "settlement:title-section", label: "Title Charges (1100)", path: "/order/settlement/title-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_OTHER_CHARGES_HUD, name: "settlement:othercost-section", label: "Other Charges (1300)", path: "/order/settlement/othercost-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_FEES, name: "settlement:fees", label: "Settlement Fees", path: "/order/settlement/fees", accessKey: "RateRules_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_NET_DISBURSEMENT_DISB, name: "settlement:net-disbursement-disb", label: "Disbursements (1500)", path: "/order/settlement/net-disbursement-disb", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_NET_DISBURSEMENT_NET, name: "settlement:net-disbursement-net", label: "Net Settlement (1600)", path: "/order/settlement/net-disbursement-net", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_CDF_PAYOFFS, name: "settlement:cdfPayoffPayments", label: "Payoffs & Payments (K)", path: "/order/settlement/cdfPayoffPayments", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_DEBIT_CREDITS, name: "settlement:debitcredits-section", label: "Debits/Credits", path: "/order/settlement/debitcredits-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_AGGREGATE_ADJUSTMENT, name: "settlement:aggregate-adjustment", label: "Aggregate Adjustment", path: "/order/settlement/aggregate-adjustment", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_REALTOR_COMMISSION, name: "settlement:realtor-commission", label: "Commissions", path: "/order/settlement/realtor-commission", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_PAID_BY_OTHERS, name: "settlement:paid-by-others", label: "Paid By Others", path: "/order/settlement/paid-by-others", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_PRORATIONS, name: "settlement:prorations", label: "Prorations", path: "/order/settlement/prorations", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_PAYOFFS, name: "settlement:payoffs:list", label: "Payoffs", path: "/order/settlement/payoffs", matchRoutePrefix: "settlement:payoffs", accessKey: "Payoff_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_RECORDING_FEES_AND_TAXES, name: "settlement:recording-fees-and-taxes", label: "Recording Fees & Taxes", path: "/order/settlement/recording-fees-and-taxes", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_WORKSHEET_801, name: "settlement:worksheet801", label: "801 Breakout Charges", path: "/order/settlement/worksheet801", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_ADDENDUMS, name: "settlement:addendums", label: "Addendums", path: "/order/settlement/addendums", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_HUD_ADDENDUMS, name: "settlement:hudAddendums", label: "Addendums", path: "/order/settlement/hudAddendums", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_ADDITIONAL_TITLE_SERVICES, name: "settlement:additional-title-services", label: "Additional Title Services", path: "/order/settlement/additional-title-services", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_LOANTERMS, name: "settlement:loanterm-section", label: "Loan Terms", path: "/order/settlement/loanterm-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_LOAN_CALCULATION, name: "settlement:loan-calculation", label: "Loan Calculations", path: "/order/settlement/loan-calculation", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_PROJECTED_PAYMENT, name: "settlement:projectedpayment-section", label: "Projected Payments", path: "/order/settlement/projectedpayment-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_CASH_TO_CLOSE, name: "settlement:cashtoclose-section", label: "Cash To Close", path: "/order/settlement/cashtoclose-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_LOAN_DISCLOSURES, name: "settlement:loan-disclosure-section", label: "Loan Disclosures", path: "/order/settlement/loan-disclosure-section", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_CONTACTS, name: "settlement:contacts", label: "CDF Contacts", path: "/order/settlement/settlement-statement-contacts", matchRoutePrefix: "settlement:contacts", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_ORDER_SUMMARY, name: "settlement:settlement-order-summary", label: "File Summary", path: "/order/settlement/settlement-order-summary", accessKey: "GenericSettlementStatement_ScreenAccess" },
                    { id: NAV_IDS.SETTLEMENT_GFE_COMPARISON, name: "settlement:gfe-comparison", label: "GFE Comparison", path: "/order/settlement/gfe-comparison", accessKey: "GenericSettlementStatement_ScreenAccess" },
                ]
            },
            { id: NAV_IDS.CSS, name: "css-main", label: "CSS", matchRoutePrefix: "css-main", path: "/order/css-main", accessKey: "CommercialSettlementStatement_ScreenAccess", featureFlag: "cssEditor" },
            { id: NAV_IDS.INVOICE, name: "o-inv:invoices", label: "Invoices", path: "/order/invoices", accessKey: "Invoices_ScreenAccess", matchRoutePrefix: "o-inv" },
            { id: NAV_IDS.ORDER_WORKFLOW, name: "ow:order-workflow", label: "File Workflow", path: "/order/workflow", accessKey: "UpdateWorkflow_ScreenAccess" },
            { id: NAV_IDS.GENERATE_DOCUMENTS, name: "generate-documents", label: "Generate Documents", matchRoutePrefix: "docs", path: "/order/documents/generate", accessKey: "Documents_ScreenAccess" },
            // { id: NAV_IDS.GENERATE_EZJACKET, name: "generate-ezjacket", label: "Generate ezJacket", matchRoutePrefix: "generate-ezjacket", path: "/order/generate-ezjacket", featureFlag: "ezJacket" },
            { id: NAV_IDS.DOCUMENT_MGMT, name: "document-mgmt", label: "Document Management", matchRoutePrefix: "document-mgmt", path: "/order/document-mgmt", permissionKeys: ["appFileScan_AppAccess"] },
            { id: NAV_IDS.CLOSING, name: "signing", label: "Signings", matchRoutePrefix: "signing", path: "/order/signing", featureFlag: "closingMenuEntry"  },
            { id: NAV_IDS.CHECK_WRITING, name: "check-writing", label: "Check Writing", matchRoutePrefix: "check-writing", path: "/order/check-writing", accessKey: "CheckWriting_ScreenAccess" },
            { id: NAV_IDS.RECORDING, name:"o-rec:recording", label: "Recording", path: "/order/recording", accessKey: "GenericSettlementStatement_ScreenAccess", matchRoutePrefix: "o-rec", featureFlag: "recordingDocuments" },
            { id: NAV_IDS.CLOSING_MARKET, name: "closing-market", label: "Closing Market", path: "/order/closing-market" },
            { id: NAV_IDS.FILE_ALERTS, name: "file-alerts", label: "File Alerts", path: "/order/file-alerts" },
            { id: NAV_IDS.AUDIT_LOG, name: "audit-log", label: "Audit Log", path: "/order/audit-log", featureFlag: "auditlog" },
        ]
    },
    {
        id: NAV_IDS.ACCOUNTING, label: "Accounting", path: "/escrow-accounting", icon: "fas fa-calculator", children: [
            { id: NAV_IDS.ACCOUNTING_CON_CHECKS, name: "ea:consolidated-checks", label: "Consolidated Checks", path: "/escrow-accounting/consolidated-checks", accessKey: "Consolidated_ScreenAccess" },
            { id: NAV_IDS.ACCOUNTING_ETB, name: "ea:etb-browser", label: "Current ETB Browser", path: "/escrow-accounting/etb-browser", permissionKeys: ["appEscrowAccounting_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_CHECKS_ENTRY, name: "ea:disbursement-browser", label: "Disbursement Browser", path: "/escrow-accounting/disbursement-browser", permissionKeys: ["appEscrowAccounting_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_EBANK_RECON, name: "ea:ebank-recon", label: "eBank Reconciliation", path: "/escrow-accounting/ebank-recon", permissionKeys: ["appEBankRecon_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_EREMIT, name: "ea:e-remit", label: "eRemit", path: "/escrow-accounting/e-remit", featureFlag: "eRemit" },
            { id: NAV_IDS.ACCOUNTING_EREMIT_FRAGMENT, name: "ea:e-remit-fragment", label: "eRemit", path: "/escrow-accounting/e-remit-fragment", featureFlag: "eRemitFragment" },
            { id: NAV_IDS.ACCOUNTING_ESCROW_ACTIVITY, name: "ea:escrow-activity", label: "Escrow Activity", path: "/escrow-accounting/escrow-activity", permissionKeys: ["appEscrowAccounting_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_WIRES, name: "ea:expected-wires", label: "Expected Wires", path: "/escrow-accounting/expected-wires", permissionKeys: ["appEscrowAccounting_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_WIRE_OUT, name: "ea:expected-wires-out", label: "Expected Wires Out", path: "/escrow-accounting/expected-wires-out", permissionKeys: ["appEscrowAccounting_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_INVOICE_BATCH_PAYMENTS, name: "ea:invoice-batch-payments", label: "Invoice Batch Payments", path: "/escrow-accounting/invoice-batch-payments", accessKey: "Invoices_ScreenAccess" },
            { id: NAV_IDS.ACCOUNTING_INVESTMENT, name: "ea:investment-accounts", label: "Open Investment Accounts", path: "/escrow-accounting/investment-accounts", accessKey: "InvestmentAccountIndividual_ScreenAccess" },
            { id: NAV_IDS.ACCOUNTING_PENDING_APPROVALS, name: "ea:pending-approvals", label: "Pending Approvals", path: "/escrow-accounting/pending-approvals", permissionKeys: ["appFinancialApprovals_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_POSITIVE_PAY, name: "ea:positive-pay", label: "Positive Pay", path: "/escrow-accounting/positive-pay", permissionKeys: ["appPositivePay_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_DEPOSITS_ENTRY, name: "ea:receipt-browser", label: "Receipt Browser", path: "/escrow-accounting/receipt-browser", permissionKeys: ["appEscrowAccounting_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_VERIFY_DEPOSITS, name: "ea:receipt-verification", label: "Receipt Verification", path: "/escrow-accounting/receipt-verification", permissionKeys: ["appEscrowAccounting_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_RECON, name: "ea:reconciliations", label: "Reconciliation", path: "/escrow-accounting/reconciliations", permissionKeys: ["appEscrowAccounting_AppAccess"] },
            { id: NAV_IDS.ACCOUNTING_WORKDAY, name: "ea:workday-integration", label: "WorkDay Integration", path: "/escrow-accounting/workday-integration", permissionKeys: ["appEscrowAccounting_AppAccess"] },
        ]
    },

    {
        id: NAV_IDS.REPORTS, label: "Reporting", path: "/reporting", icon: "fas fa-chart-pie", children: [
            { id: NAV_IDS.REPORTS_1099, name: "rp:1099", label: "1099 Filing", path: "/reporting/1099-filing", permissionKeys: ["app1099_AppAccess"] },
            { id: NAV_IDS.REPORT_MANAGEMENT, name: "rp:report-management", label: "Report Management", path: "/reporting/report-management", featureFlag: "reportsCreator", permissionKeys: ["CanCreateReports"] },
            { id: NAV_IDS.REPORT_GENERATION, name: "rp:report-generation", label: "Report Generation", path: "/reporting/report-generation", permissionKeys: ["appReports_AppAccess"] },
        ]
    },
    {
        id: NAV_IDS.CONFIG,
        name: "cfg:search",
        label: "Configuration",
        path: "/configuration/search",
        icon: "fas fa-cog",
        matchRoutePrefix: "configuration",
        navigate: true,
        accessKey: "ProcessTemplateSetup_ScreenAccess",
        permissionKeys: [
            "appWordDocHelper_AppAccess",
            "appStandardLanguage_AppAccess",
            "AllowAddEditRates",
            "AllowAddEditRecordingFees",
            "AllowAddEditTransferTaxes",
            "AllowEditEndorsementSettlementFees",
            "FileScanAllowDescriptionManagement",
            "FileScanAllowCategoryManagement"
        ]
    }
];

import { DemoNavItems } from "../modules/demos/nav";
const demoNavEnabled = _.parseBool(appSettings.demoNavEnabled);
if (demoNavEnabled) {
    itemData.push(DemoNavItems);
}

export default { items: itemData };
